<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>{{browsing_interface_title}}</h3>

	<p><span v-html="app_name"/> provides a number of different views and functionalities for exploring CASE frameworks.</p>
	<ul>
		<li>The <span v-html="link('treeview', '“tree view”')"></span> is the default mode for viewing a framework.</li>
		<li>The <span v-html="link('tileview', '“tile view”')"></span> provides an alternative way to visualize the standards in a framework, which can be particularly valuable when viewing frameworks on a smartphone-sized screen.</li>
		<li>Whole frameworks, individual items, or any “branch” of a framework tree can be <span v-html="link('export_options', 'printed')"></span>. Other <span v-html="link('export_options', 'export options')"></span> are also available</li>
		<li><span v-html="link('tableitemsview', 'Items')"></span> and <span v-html="link('tableassociationsview', 'associations')"></span> can also be shown in table views.</li>
		<li>You can conduct basic, wildcard, and AI-powered <span v-html="link('search', 'searches')"></span> of framework items.</li>
		<li><img srcset="/docimages/kebab_menu-1.png 3x" class="k-help-img float-right">Additional viewing options are available via the <span v-html="link('kebab_menu', 'kebab menu')"></span> in the upper-right corner of the window.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	